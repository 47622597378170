import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function OnboardingEdit(props) {
  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [Note, setNotes] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [client, setClient] = useState(null);
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState("");
  const [Previous, setPrevious] = useState("");
  const [Referral, setReferral] = useState("");
  const [Recipient, setRecipient] = useState("");
  const [Location, setLocation] = useState("");
  const exp = props.expiry;
  console.log(props.client_id);
  const [username, setUsername] = useState(null);
  const [whatsappProof, setWhatsappProof] = useState(props.whatsapp === 1);
  const [appInstalled, setAppInstalled] = useState(props.app === 1);
  const [clients, setClients] = useState([]);
  const [expirationDay, setExpirationDay] = useState(null); // New state for expiration day

  const validate = () => {
    return (
      client !== null &&
      Note !== "" &&
      paymentMethod !== "" &&
      username !== null
    );
  };

  useEffect(() => {
    let isMounted = true;

    const getClients = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/clients/onboarding/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setClients(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getClients();

    return () => {
      isMounted = false;
    };
  }, []);

  const paymentOptions = [
    { value: "Office", label: "Office" },
    { value: "OMT", label: "OMT" },
    { value: "WISH", label: "WISH" },
    { value: "Collector", label: "Collector" },
  ];

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 1.2 seconds
    setTimeout(() => {}, 1200);

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage(null);
      props.close();
      props.fetch();
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.post(
        `${API_URL}/radius/subscriptions/clients/onboarding/`,
        {
          Action: "Put",
          client_id: props.client_id,
          onboarding_id: props.onboarding_id,
          Note: Note,
          payment: paymentMethod,
          expiration_day: expirationDay, // Include expirationDay in the submission
          phone: phone,
          Previous: Previous,
          referral: Referral,
          userdealer: user.username,
          whatsapp_proof: whatsappProof,
          app_installed: appInstalled,
          recipient: Recipient,
          location: Location,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        showMessage("Edit successful!", "success");
        // Refetch the client data
        // Reset the form fields
        setNotes("");
        setPaymentMethod("");
        setDate("");
        setPhone("");
        setPrevious("");
        setUsername(null);
        setWhatsappProof(false);
        setAppInstalled(false);
      }
    } catch (error) {
      console.log(error);
      showMessage("Edit failed!", "danger");
    }
  };

  return (
    <div className="container">
      <div className="card mt-5">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Payment Method:</label>
                <Select
                  className="search-line"
                  placeholder={props.payment}
                  options={paymentOptions}
                  onChange={(opt) => setPaymentMethod(opt.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Phone:</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={props.phone}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>Previous Reseller:</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={props.previous}
                  value={Previous}
                  onChange={(e) => setPrevious(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Referral:</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={props.referral}
                  value={Referral}
                  onChange={(e) => setReferral(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Location:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Location}
                  placeholder={props.mgrs}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Recipient:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Recipient}
                  placeholder={props.recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Notes:</label>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder={props.notes}
                  value={Note}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
            <div className="col-md-6 form-group">
                <label>Expiration Day:</label>
                <Select
                  className="search-line"
                  placeholder="Choose Expiration Day"
                  options={Array.from({ length: 27 }, (_, i) => ({
                    label: (i + 2).toString(), // days between 2 and 28
                    value: i + 2,
                  }))}
                  onChange={(selectedOption) => {
                    setExpirationDay(selectedOption.value);
                  }}
                />
              </div>
              <div className="col-md-6 form-group">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="whatsappProof"
                    checked={whatsappProof}
                    onChange={(e) => setWhatsappProof(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="whatsappProof">
                    WhatsApp Proof
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="appInstalled"
                    checked={appInstalled}
                    onChange={(e) => setAppInstalled(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="appInstalled">
                    App Installed
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-md">
                Edit{" "}
              </button>
            </div>
          </form>
        </div>
      </div>

      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default OnboardingEdit;
