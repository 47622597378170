import React, { useState, useEffect, useContext, useCallback } from "react"; 
import Axios from "axios";
import Select from "react-select"; // Import Select component
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import "./discounts.css"; // Import the CSS file

function OfferForm(props) {
  const [months, setMonths] = useState([]);
  const [year, setYear] = useState("");
  const [offers, setOffers] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [message, setMessage] = useState(""); // State for messages
  const [messageType, setMessageType] = useState(""); // State for message type (error or success)
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  //Get the client username and subscription_id
  const getClient = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/discount/add/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: { username: user.username, client_id: props.client_id },
        }
      );

      if (response.status === 200) {
        const options = response.data.map(client => ({
          value: client.subscription_id,
          label: client.username,
        }));
        setClientOptions(options);
      }
    } catch (error) {
      console.error(error);
    }
  }, [authTokens.access, user.username, props.client_id]);

  //get the current date 
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // Months are zero-based (0 for January, 11 for December)

  //a list of monthly options
  const allMonthOptions = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];

  //get the present and future months if it is the current year
  const filteredMonthOptions = year === currentYear.toString()
    ? allMonthOptions.slice(currentMonth)
    : allMonthOptions;

  //refreshes the page if it is the current year
  const refreshPage = () => {
    window.location.reload(false);
  };

  //gets offers of the selected subscription_id
  const getOffers = useCallback(async () => {
      console.log("Fetching offers for subscription ID:", selectedSubscriptionId); // Log the subscription ID
  
      try {
        const response = await Axios.get(`${API_URL}/accounting/offers/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: { username: user.username, subscription_id: selectedSubscriptionId,client_id:props.client_id }, // Use the selected subscription ID
        });
  
        if (response.status === 200) {
          console.log("Offers retrieved:", response.data); // Log the offers data
          setOffers(response.data);
        }
      } catch (error) {
        console.error("Error fetching offers:", error); // Log errors
      }
    
  }, [authTokens.access, user.username]); // Depend on the selectedSubscriptionId
  
  



  useEffect(() => {
    if (props.client_id) {
      getClient();
    }
  }, [props.client_id, getClient]);

  useEffect(() => {
    getOffers();
  }, [selectedClient, getOffers]);

  // Submit the offer to the client
const handleSubmit = async (e) => {
  e.preventDefault();

  // Check if any months have been selected
  if (!months.length) {
    setMessage("Please select at least one month.");
    setMessageType("error");
    return;
  }

  // Check if a subscription ID has been selected
  if (!selectedSubscriptionId) {
    setMessage("Please select a client.");
    setMessageType("error");
    return;
  }

  try {
    const response = await Axios.post(
      `${API_URL}/accounting/offers/`,
      {
        username: user.username,
        subscription_id: selectedSubscriptionId, // Use the selected subscription ID here
        months: months,
        year: year,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
      }
    );

    setMessage("Offer is added successfully!");
    setMessageType("success");
    console.log(response.data);
  } catch (error) {
    setMessage("An error occurred while adding the offer.");
    setMessageType("error");
    console.error(error);
  }
};

  

  //handles the month(s) selection
  const handleMonthChange = (selectedOptions) => {
    setMonths(selectedOptions.map((option) => option.value));
  };

  //handle the change of the year
  const handleYearChange = (e) => {
    setYear(e.target.value);
    setMonths([]);
  };

  return (
    <div className="offer-form-container">
      <form onSubmit={handleSubmit} className="offer-form">
        <div className="form-group">
          <label htmlFor="client-username">Client Username:</label>
          <Select
      id="client-username"
      className="select-input"
      options={clientOptions}
      placeholder="Select Client Username"
      isDisabled={clientOptions.length === 0}
      onChange={(selectedOption) => {
        console.log("Selected client option:", selectedOption);
        setSelectedSubscriptionId(selectedOption.value); // Set the selected subscription ID
      }}
        value={clientOptions.find(option => option.value === selectedSubscriptionId) || null} // Set value to the selected subscription ID
    />

        </div>
        <div className="form-group">
          <label htmlFor="year">Year:</label>
          <input
            id="year"
            className="form-control"
            type="number"
            value={year}
            placeholder="Enter Year"
            min={currentYear}
            onChange={handleYearChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="months">Months:</label>
          <Select
            id="months"
            className="select-input"
            isMulti
            placeholder="Select Months"
            options={filteredMonthOptions}
            onChange={handleMonthChange}
            value={filteredMonthOptions.filter((option) => months.includes(option.value))}
            isDisabled={!year}
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-primary">
            Submit
          </button>
        </div>

        {/* Display the message */}
        {message && (
          <div className={`message ${messageType === 'error' ? 'error' : 'success'}`}>
            {message}
          </div>
        )}
      </form>

      <div className="offer-list">
        <h3>Current Offers</h3>
        {offers.length > 0 ? (
          <table className="offer-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Months</th>
                <th>Year</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              {offers.map((offer) => (
                <tr key={offer.offer_id}>
                  <td>{offer.offer_id}</td>
                  <td>{offer.month}</td>
                  <td>{offer.year}</td>
                  <td>{offer.username}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No offers available.</p>
        )}
      </div>
</div>
  );
}

export default OfferForm;
