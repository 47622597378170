import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "./Clients.css";
import Formo from "./Editform";
import ExcelSVG from "../../ExcelSVG.svg";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

function ClientTable() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [filteredRowsData, setFilteredRowsData] = useState([]);
  const [searchQueryNoMiddleName, setSearchQueryNoMiddleName] = useState(""); // New search state
  const [filteredRowsNoMiddleName, setFilteredRowsNoMiddleName] = useState([]); // Filtered rows for no middle name

  const [ClientDetails, setClientDetails] = useState({
    client_id: 0,
    info_id: 0,
    balance_id: 0,
    balance: 0,
    address_id: "",
    first_name: 0,
    middle_name: 0,
    last_name: "",
    phone_number: "",
    address_string: "",
    village_name: "",
    remote: "",
    office: "",
    category: "",
    employee_en: "",
  });

  const updateClientDetails = (details) => {
    setClientDetails(details);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
      renderCell: (params) => (
        <span style={{ color: params.row.has_middle_name ? "red" : "white" }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        // Check if phone number length is 7, then add '0' at the beginning
        let phone = params.row.phone_number;
        if (phone && phone.length === 7) {
          phone = "0" + phone; // Concatenate '0' to the phone number
        }
        return <span>{phone}</span>;
      },
    },
    {
      field: "village_name",
      headerName: "Village Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
    },
    { field: "address_string", headerName: "Address", flex: 1, minWidth: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 70,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                client_id: params.row.client_id,
                address_id: params.row.address_id,
                balance_id: params.row.balance_id,
                balance: params.row.balance_usd,
                info_id: params.row.info_id,
                first_name: params.row.first_name,
                middle_name: params.row.middle_name,
                last_name: params.row.last_name,
                phone_number: params.row.phone_number,
                address_string: params.row.address_string,
                village_name: params.row.village_name,
                remote: params.row.temp_collection,
                office: params.row.temp_collection_office,
                category: params.row.category,
                employee_en: params.row.employee_en,
              };
              updateClientDetails(details);
              handleShow();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.client_id;

  useEffect(() => {
    const searchValue = sessionStorage.getItem("searchClient");
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchClient") {
        sessionStorage.removeItem(key);
      }
    });
    if (searchValue) {
      sessionStorage.setItem("searchClient", searchValue);
      setSearchQuery(searchValue);
    }
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchClient", query);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;
          const cellValue = row[columnField];
          return cellValue !== undefined && value !== undefined
            ? String(cellValue).toLowerCase().includes(value.toLowerCase())
            : false;
        });
      });
      setFilteredRowsData(filteredData);
    } else {
      setFilteredRowsData(data);
    }
  };

  const clientsWithoutMiddleName = data.filter((client) => !client.middle_name);

  const columnHeaders = {
    fullname_en: "Full Name",
    phone_number: "Phone Number",
    village_name: "Village Name",
    category: "Category",
    address_string: "Address",
  };

  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  const handleExportToExcel = () => {
    const transformedRows =
      filteredRowsData.length > 0
        ? transformRowsForExport(filteredRowsData)
        : transformRowsForExport(filteredRows);
    const worksheet = XLSX.utils.json_to_sheet(transformedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");
    XLSX.writeFile(workbook, "Clients.xlsx");
  };

  const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    setTimeout(() => {
      setIsPressed(false);
    }, 200);
  };

  // Handle search for "Clients Without Middle Name"
  const handleSearchNoMiddleName = (event) => {
    const query = event.target.value;
    setSearchQueryNoMiddleName(query);
    sessionStorage.setItem("searchClientNoMiddleName", query); // Save search query to session
  };

  // UseEffect to persist search session for "Clients Without Middle Name"
  useEffect(() => {
    const searchValueNoMiddleName = sessionStorage.getItem(
      "searchClientNoMiddleName"
    );
    if (searchValueNoMiddleName) {
      setSearchQueryNoMiddleName(searchValueNoMiddleName);
    }
  }, []);

  const filteredRowsNoMiddleNames = clientsWithoutMiddleName.filter((row) => {
    const searchWords = searchQueryNoMiddleName.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: "14px",
          border: "none",
          backgroundColor: isPressed ? "white" : "transparent",
          color: isPressed ? "black" : "#007bff",
          cursor: "pointer",
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: "20px",
            marginRight: "8px",
            filter: isPressed ? "invert(0)" : "invert(1)",
          }}
        />
        Excel
      </Button>
    </GridToolbarContainer>
  );

  return (
    <Tabs
      defaultActiveKey="allClients"
      id="client-tabs"
      className="mb-3 d-flex align-items-center"
    >
      <Tab eventKey="allClients" title="All Clients">
        <div className="ClientTable">
          {/* Content for All Clients */}
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search-client"
          />
          {!loading && data.length > 0 && (
            <div style={{ height: 520, width: "100%" }}>
              <DataGrid
                rows={filteredRows}
                columns={columns}
                getRowId={getRowId}
                components={{
                  Toolbar: CustomToolbar,
                }}
                rowsPerPageOptions={[10, 100, 1000]}
                pagination
                disableSelectionOnClick
                onFilterModelChange={handleFilterChange}
              />
            </div>
          )}
        </div>
      </Tab>

      <Tab
        eventKey="clientsWithoutMiddleName"
        title="Clients Without Middle Name"
      >
        <div className="ClientTable">
          <input
            type="text"
            placeholder="Search Clients Without Middle Name..."
            value={searchQueryNoMiddleName}
            onChange={handleSearchNoMiddleName}
            className="search-client"
          />
          {!loading && filteredRowsNoMiddleNames.length > 0 && (
            <div style={{ height: 520, width: "100%", color: "white" }}>
              <DataGrid
                rows={filteredRowsNoMiddleNames}
                columns={columns}
                getRowId={getRowId}
                components={{
                  Toolbar: CustomToolbar,
                }}
                rowsPerPageOptions={[10, 100, 1000]}
                pagination
                disableSelectionOnClick
                onFilterModelChange={handleFilterChange}
                sx={{
                  ".MuiDataGrid-cell span": {
                    color: "white !important",
                  },
                }}
              />
            </div>
          )}
          <Modal show={show} onHide={handleClose} className="modal-lg">
            <Modal.Header closeButton>
              <Modal.Title>Edit Client</Modal.Title>
            </Modal.Header>
            <div className="forma">
              <Formo
                sub={ClientDetails.client_id}
                add={ClientDetails.address_id}
                inf={ClientDetails.info_id}
                fir={ClientDetails.first_name}
                mid={ClientDetails.middle_name}
                las={ClientDetails.last_name}
                addr={ClientDetails.address_string}
                pho={ClientDetails.phone_number}
                vill={ClientDetails.village_name}
                cat={ClientDetails.category}
                balance_id={ClientDetails.balance_id}
                balance={ClientDetails.balance}
                employee_id={ClientDetails.employee_en}
                remote={ClientDetails.remote}
                office={ClientDetails.office}
              />
            </div>
          </Modal>
        </div>
      </Tab>
    </Tabs>
  );
}

export default ClientTable;
