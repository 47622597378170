import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";

function EditService(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [code, setcode] = useState("");
  const [cost, setcost] = useState("");
  const [price, setprice] = useState("");
  const [cat, setcat] = useState([]);

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      refreshPage();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/services/`,
      {
        Action: "Put",
        service: props.service_id,
        name: name,
        description: description,
        code: code,
        cost: cost,
        price: price,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Name:
              <input
                className="form-control"
                type="text"
                value={name}
                placeholder={props.name}
                onChange={(e) => setname(e.target.value)}
              />
            </label>
            <label>
              Description:
              <input
                className="form-control"
                type="text"
                value={description}
                placeholder={props.description}
                onChange={(e) => setdescription(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Code:
              <input
                className="form-control"
                type="text"
                value={code}
                placeholder={props.code}
                onChange={(e) => setcode(e.target.value)}
              />
            </label>
          </div>

          <label>
            Cost:
            <input
              className="form-control"
              type="number"
              step="0.1"
              min="0"
              value={cost}
              placeholder={props.cost}
              onChange={(e) => setcost(e.target.value)}
            />
          </label>
          <label>
            Price:
            <input
              className="form-control"
              type="number"
              step="0.1"
              min="0"
              value={price}
              placeholder={props.price}
              onChange={(e) => setprice(e.target.value)}
            />
          </label>

          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Edit
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default EditService;
