import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select"; // Import React-Select
import "./TransferDays.css";

const MAX_TRANSFER_DAYS = 365;
const MIN_TRANSFER_DAYS = 7;

function TransferDays() {
  const { user } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    fromUsername: null,
    toUsername: null,
    fromExpiryDate: "",
    toExpiryDate: "",
    transferDays: "",
    maxTransferDays: null,
    fromSellingPrice: null,
    toSellingPrice: null,
  });
  const [userOptions, setUserOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  useEffect(() => {
    fetchUsernames();
  }, []);

  // Fetch usernames for dropdown options
  const fetchUsernames = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${API_URL}/radius/subscriptions/username/`,
        {
          headers: { Authorization: `Bearer ${authTokens.access}` },
          params: { username: user.username },
        }
      );
      const options = response.data.map((item) => ({
        value: item.username,
        label: `${item.fullname_en} (${item.username}) Plan: ${
          item.plan_name
        } - Expiry: ${new Date(item.expiration_date).toLocaleDateString(
          "en-GB"
        )}`,
        expiryDate: item.expiration_date,
        selling_price: item.selling_price,
      }));
      setUserOptions(options);
    } catch (error) {
      console.error("Error fetching usernames:", error);
      setError("Failed to load usernames. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Custom filter function for searching
  const customFilter = (option, inputValue) => {
    const words = inputValue.toLowerCase().split(" ");
    return words.every((word) => option.label.toLowerCase().includes(word));
  };

  // Calculate the max transfer days
  // Calculate the max transfer days
const calculateMaxTransferDays = (fromOption) => {
  if (!fromOption) return;
  const fromDate = new Date(fromOption.expiryDate);
  const today = new Date();
  
  // Calculate the difference in days between the from expiry date and the current date
  const diffTime = fromDate - today;
  const diffDays = Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24))); // Ensure non-negative value
  
  // Update the state with the calculated max transfer days
  setFormState((prevState) => ({
    ...prevState,
    maxTransferDays: Math.min(diffDays, MAX_TRANSFER_DAYS),
  }));
};


const handleSelectChange = (field, selected) => {
  setFormState((prevState) => ({
    ...prevState,
    [field]: selected,
    [`${field}SellingPrice`]: selected.selling_price,
  }));

  // Only calculate max transferable days if "fromUsername" is selected
  if (field === "fromUsername") {
    calculateMaxTransferDays(selected);
  }
};

  const handleTransfer = async () => {
    const { fromUsername, toUsername, transferDays } = formState;
  
    if (!fromUsername || !toUsername) {
      setError("Both 'From' and 'To' usernames must be selected.");
      return;
    }
  
    const fromDate = new Date(fromUsername.expiryDate);
    const toDate = new Date(toUsername.expiryDate);
    const today = new Date();
  
    // Check if either expiry date is in the past
    if (fromDate < today) {
      setError("The 'From' expiry date is in the past. Please select a valid account.");
      return;
    }
  
    if (toDate < today) {
      setError("The 'To' expiry date is in the past. Please select a valid account.");
      return;
    }
  
    const daysToTransfer =
      parseInt(transferDays, 10) || formState.maxTransferDays || MAX_TRANSFER_DAYS;
  
    if (
      !daysToTransfer ||
      isNaN(daysToTransfer) ||
      daysToTransfer < MIN_TRANSFER_DAYS ||
      daysToTransfer > MAX_TRANSFER_DAYS
    ) {
      setError(
        `Invalid number of days to transfer. Must be between ${MIN_TRANSFER_DAYS} and ${MAX_TRANSFER_DAYS}.`
      );
      return;
    }
  
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
  
    try {
      const response = await axios.post(
        `${API_URL}/radius/subscriptions/transfer/`,
        {
          userdealer: user.username,
          from_username: fromUsername.value,
          to_username: toUsername.value,
          from_expiry_date: formState.fromExpiryDate,
          to_expiry_date: formState.toExpiryDate,
          transfer_days: daysToTransfer,
          from_selling_price: formState.fromSellingPrice,
          to_selling_price: formState.toSellingPrice,
        },
        { headers: { Authorization: `Bearer ${authTokens.access}` } }
      );
      setSuccessMessage(response.data.message);
      setFormState({
        fromUsername: null,
        toUsername: null,
        fromExpiryDate: "",
        toExpiryDate: "",
        transferDays: "",
        maxTransferDays: null,
        fromSellingPrice: null,
        toSellingPrice: null,
      });
    } catch (error) {
      if (error.response && error.response.data) {
        setError(`Failed to transfer days: ${error.response.data.error}`);
      } else {
        setError("Failed to transfer days. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="transfer-days-container">
      <Card className="p-4 shadow">
        <h3 className="text-center mb-4">Transfer Days</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>From Username:</Form.Label>
            <Select
              options={userOptions.filter(
                (option) => option.value !== formState.toUsername?.value
              )}
              value={formState.fromUsername}
              onChange={(selected) =>
                handleSelectChange("fromUsername", selected)
              }
              isLoading={loading}
              isSearchable
              placeholder="Select or type a username"
              filterOption={customFilter}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>To Username:</Form.Label>
            <Select
              options={userOptions.filter(
                (option) => option.value !== formState.fromUsername?.value
              )}
              value={formState.toUsername}
              onChange={(selected) =>
                handleSelectChange("toUsername", selected)
              }
              isLoading={loading}
              isSearchable
              placeholder="Select or type a username"
              filterOption={customFilter}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Days to Transfer:</Form.Label>
            <Form.Control
              type="text"
              value={formState.maxTransferDays || MAX_TRANSFER_DAYS}
              readOnly
              placeholder="Max transferable days"
            />
          </Form.Group>

          <Button
            variant="secondary"
            onClick={handleTransfer}
            disabled={loading || !formState.maxTransferDays}
            className="ms-2"
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Transfer All Days"
            )}
          </Button>
        </Form>
      </Card>
    </div>
  );
}

export default TransferDays;
