// Import necessary dependencies and components
import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert"; // Import Alert for messages
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import WAPTable from "./WAPTable";
import Select from "react-select";

// Main Apartments component
function WirelessAccessPoint() {
  // State hook to manage modal visibility
  const [show, setShow] = useState(false);

  // Functions to handle modal open and close actions
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="Buildings">
      {/* Button to trigger the modal for adding an apartment */}
      <button onClick={handleShow} className="edit-client" style={{marginTop:"10px", marginLeft:"10px"}}>
        Add Wireless Access Point
      </button>
      <div className="main">
        {/* Render ApartmentsTable component to display list of apartments */}
        <WAPTable />
      </div>
      {/* Modal to display the apartment form */}
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Wireless Access Point</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* ApartmentForm component is passed parentFunction to close modal */}
          <WAPForm parentFunction={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

// ApartmentForm component where user can add apartment details
function WAPForm(props) {
  // Context hook to get current user details
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  // State hooks to manage form data and UI state
  const [sites, setSites] = useState([]);
  const [name, setName] = useState("");
  const [ip, setIp] = useState("");
  const[mac,setMac]=useState("")
  const [frequency, setFrequency] = useState(0);
  const [ssid, setSsid] = useState("");
  const [siteID, setSiteID] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // useEffect hook to fetch buildings data from API
  useEffect(() => {
    let isMounted = true; // Prevent state updates after component unmounts

    const getsites = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/sites/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setSites(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getsites();

    // Cleanup function to set isMounted to false to prevent updates after unmount
    return () => {
      isMounted = false;
    };
  }, []);

 
  const options = sites.map((item) => {
    return {
      label: item.site_name,
      value: item.site_id,
    };
  });
  

  // Function to handle form submission for adding an apartment
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload on form submission

    // API request to add apartment data
    Axios.post(
      `${API_URL}/radius/wirelessAccessPoint/table/`,
      {
        Action: "Post",
        name: name,
        ip: ip,
        mac: mac,
        frequency: frequency,
        ssid: ssid,
        site_id: siteID,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log("Wireless Access Point added:", response);
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Hide success message after 3 seconds
        props.parentFunction(); // Close modal after success
      })
      .catch((error) => {
        console.error("Error adding Wireless Access Point:", error);
        setErrorMessage(
          error.response?.data?.message || "An error occurred while adding the Wireless Access Point."
        );
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Hide error message after 5 seconds
      });
  };
  return (
    <>
      {/* Display success or error messages based on form submission status */}
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          Apartment added successfully!
        </Alert>
      )}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      {/* Form to input apartment details */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Wireless Access Point Name:</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)} // Update apartment name on change
            placeholder="Enter wireless access point name"
            required
          />
        </div>
        <div className="form-group">
          <label>IP Address:</label>
          <input
            type="text"
            
            className="form-control"
            value={ip}
            onChange={(e) => setIp(e.target.value)} // Update level on change
            placeholder="Enter IP Address"
            required
          />
        </div>
        <div className="form-group">
          <label>Mac Address:</label>
          <input
            type="text"
            
            className="form-control"
            value={mac}
            onChange={(e) => setMac(e.target.value)} // Update level on change
            placeholder="Enter Mac Address"
            required
          />
        </div>
        <div className="form-group">
        <label>Frequency:</label>
        <input
          type="number"
          min={0}
          className="form-control"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)} // Update level on change
          placeholder="Enter frequency"
          required
        />
      </div>
        <div className="form-group">
          <label>SSID:</label>
          <input
            type="text"
            className="form-control"
            value={ssid}
            onChange={(e) => setSsid(e.target.value)} // Update MGRS coordinates on change
            placeholder="Enter SSID"
            required
          />
        </div> 
          <div className="form-group">
                <label>
              Site Name:
              <Select
                className="search-line"
                options={options}
                onChange={(selectedOption) => setSiteID(selectedOption?.value || "")}
              />
              </label>
            </div>
        {/* Submit button to add apartment */}
        <button type="submit" className="btn btn-success">
          Add Wireless Access Point
        </button>
      </form>
    </>
  );
}

export default WirelessAccessPoint; // Export Apartments component
