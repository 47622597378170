import React, { useState, useEffect, useContext, useRef } from "react";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridFooter,
  GridPagination,
} from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import CustomizedActions from "./BulkActions";
import AuthContext from "../context/AuthContext";
import Formsa from "./editsub";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ProgressBar from "./progress.js";
import "./Internet.css";
import Cards from "./labels";
import ExcelSVG from "../../ExcelSVG.svg";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";

function SubInternet(props) {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedRows, setCheckedRows] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // To capture search input
  const [searchQuery, setSearchQuery] = useState("");
  const searchFilterTimeoutRef = useRef(null); // Ref for search filter timeout
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null); // For delayed search filter
  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  const [showo, setShowo] = useState(false);
  const [password, setpassword] = useState(0);
  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const handleUpdateAct = (id) => setSelectedSubscriptionId(id);
  const [filters, setFilters] = useState({
    username: "",
    site: "",
    planName: "",
    fullName: "",
    expDate: "",
    consumption: "",
    connectivity: "",
    connectivity_dependent: "",
    due: "",
    payment_date: "",
    label: "",
    site_name: "",
    WAP: "",
    core: "",
    mac_address: "",
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [gridFilterModel, setGridFilterModel] = useState({
    items: [],
  });
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    username: "",
    password: 0,
    subId: 0,
    intSubId: 0,
    intPlanId: 0,
    site: "",
    planName: "",
    fullName: "",
    expDate: "",
    consumption: "",
    connectivity: "",
    due: "",
    payment_date: "",
    label: "",
    site_name: "",
    WAP: "",
    core: "",
    mac_address: "",
  });

  const updateSubscriptionDetails = (details) => {
    setSubscriptionDetails(details);
  };

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/radius/reseller/subscriptions/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            dealer: props.selectedOption,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();

    // Set up a timer to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, [user.username]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const getPercentageCellValue = (params) => {
    if (params.row.plan_name.includes("STARTER")) {
      return params.row.monthly_percentage + "%";
    }
    return params.row.percentage + "%";
  };

  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedRows = () => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return filteredRows.slice(start, end);
  };

  const areAllRowsSelected = () => {
    const currentViewRows = paginatedRows();
    const currentViewIds = currentViewRows.map((row) => row.subscription_id);
    return currentViewIds.every((id) => checkedRows.includes(id));
  };

  const handleSelectAllCheckboxes = () => {
    const currentViewRows = paginatedRows(); // Get rows currently visible
    const currentViewIds = currentViewRows.map((row) => row.subscription_id);

    if (areAllRowsSelected()) {
      // Deselect if all currently visible rows are selected
      setCheckedRows(checkedRows.filter((id) => !currentViewIds.includes(id)));
    } else {
      // Select all visible rows if not all are selected
      setCheckedRows([
        ...checkedRows,
        ...currentViewIds.filter((id) => !checkedRows.includes(id)),
      ]);
    }
  };

  const calculateUptime = (sessionTimeInSeconds) => {
    // Convert session time in seconds to hours, minutes, and seconds
    const hours = Math.floor(sessionTimeInSeconds / 3600);
    const minutes = Math.floor((sessionTimeInSeconds % 3600) / 60);
    const seconds = sessionTimeInSeconds % 60;

    return `${hours} h ${minutes} m ${seconds} s`;
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 100,
      renderHeader: () => (
        <Checkbox
          indeterminate={
            checkedRows.length > 0 && checkedRows.length < data.length
          }
          checked={checkedRows.length === data.length}
          onChange={(event) => handleSelectAllCheckboxes(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          className="subcheck"
          checked={checkedRows.includes(params.row.subscription_id)}
          onChange={(event) =>
            handleCheckboxChange(event, params.row.subscription_id)
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 70,
      cellClassName: (params) => getStatusCellStyle(params), // Apply cell style based on status
    },
    { field: "username", headerName: "Username", minWidth: 100 },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    { field: "suffix", headerName: "Suffix", flex: 1, minWidth: 150 },

    {
      field: "plan_name",
      headerName: "Plan Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let max = params.row.daily_limit;
        let download = params.row.pppoe_download;
        let upload = params.row.pppoe_upload;

        if (params.row.plan_name.includes("STARTER")) {
          max = params.row.monthly_limit;
          download = params.row.monthly_download;
          upload = params.row.monthly_upload;
        }

        return (
          <ProgressBar
            planName={params.row.plan_name}
            max={max}
            download={download}
            upload={upload}
          />
        );
      },
    },
    {
      field: "labels",
      headerName: "Labels",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Cards sub_id={params.row.subscription_id} />;
      },
    },
    { field: "ip_address", headerName: "IP Address", flex: 1, minWidth: 120 },
    {
      field: "mac_address",
      headerName: "Mac Address",
      flex: 1,
      minWidth: 170,
    },
    { field: "site", headerName: "Site/Core", flex: 1, minWidth: 85 },
    {
      field: "connectivity_dependent",
      headerName: "Connectivity Info",
      flex: 1,
      minWidth: 110,
      valueGetter: (params) => {
        if (params.row.connectivity === "Fiber") {
          return params.row.code || ""; // Use "code" for Fiber connectivity
        } else if (params.row.connectivity === "Wireless") {
          return params.row.name || ""; // Use WAP for Wireless connectivity
        }
        return ""; // Default to an empty string
      },
      renderCell: (params) => {
        if (params.row.connectivity === "Fiber") {
          return params.row.code; // Display "code" for Fiber connectivity
        } else if (params.row.connectivity === "Wireless") {
          return params.row.name; // Display WAP for Wireless connectivity
        }
        return ""; // Default display
      },
    },
    {
      field: "subscription_secret",
      headerName: "Token",
      flex: 1,
      minWidth: 95,
    },
    { field: "dealer_en", headerName: "Reseller", flex: 1, minWidth: 80 },
    {
      field: "connectivity",
      headerName: "connectivity",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "consumption_option",
      headerName: "Option",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "points",
      headerName: "Omegas",
      flex: 1,
      minWidth: 70,
    },

    {
      field: "uptime",
      headerName: "Uptime",
      width: 150,
      valueGetter: (params) => {
        const latestStartTime = params.row.latest_session_time; // Assuming `latest_start_time` exists in each row
        return latestStartTime ? calculateUptime(latestStartTime) : "N/A";
      },
    },
    { field: "due", headerName: "Due", flex: 1, minWidth: 50 },
    {
      field: "payment_date",
      headerName: "Payment",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "expiration_date",
      headerName: "Expiry",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "creation_date",
      headerName: "Creation",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 65,
      renderCell: (params) => (
        <div>
          <div
            onClick={() => handleUpdateAct(params.row.subscription_id)}
          ></div>
          <button
            onClick={() => {
              const details = {
                username: params.row.username,
                password: params.row.password,
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
                intPlanId: params.row.internet_plan_id,
                site: params.row.site,
                planName: params.row.plan_name,
                fullName: params.row.fullname_en,
                expDate: params.row.expiration_date,
                consumption: params.row.consumption_option,
                connectivity: params.row.connectivity,
                due: params.row.due,
                payment_date: params.row.payment_date,
                label: params.row.label,
                site_name: params.row.site_name,
                WAP: params.row.name,
                mac_address: params.row.mac_address,
                core: params.row.code,
              };
              updateSubscriptionDetails(details);
              handleshowo();
            }}
            className="edit-client"
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.subscription_id;

  const handleCheckboxChange = (event, subscriptionId) => {
    const checked = event.target.checked;
    setCheckedRows((prevCheckedRows) => {
      if (checked) {
        return [...prevCheckedRows, subscriptionId];
      } else {
        return prevCheckedRows.filter((id) => id !== subscriptionId);
      }
    });
  };

  // Create a delayed search filter function
  const delaySearchFilter = (input) => {
    setSearchQuery(input);
  };

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchResellerSubscription");

    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchResellerSubscription") {
        sessionStorage.removeItem(key);
      }
    });

    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchResellerSubscription", searchValue);
      setSearchQuery(searchValue);
    }
  }, []);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchInput(query);
    sessionStorage.setItem("searchResellerSubscription", query);
    setCurrentPage(0);

    // Clear the previous timeout if it exists
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }

    // Set a new timeout to trigger filtering after a delay
    const newTimeout = setTimeout(() => {
      delaySearchFilter(query);
    }, 100); // Adjust the delay as needed
    setSearchFilterTimeout(newTimeout);
  };

  const filteredRows = data.filter((row) => {
    // Combine search and filters
    const searchMatch = searchQuery
      .split(" ")
      .every((word) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(word.toLowerCase().trim())
        )
      );

    // Check if the row matches all filters
    const filterMatch = Object.keys(filters).every((key) => {
      if (!filters[key]) return true;

      // Special handling for connectivity_dependent filter
      if (key === "connectivity_dependent") {
        const filterValue = filters[key].toLowerCase();
        if (row.connectivity === "Fiber") {
          return (row.code || "").toLowerCase().includes(filterValue);
        } else if (row.connectivity === "Wireless") {
          return (row.name || "").toLowerCase().includes(filterValue);
        }
        return false;
      }

      // Default filter handling for other fields
      return String(row[key] || "")
        .toLowerCase()
        .includes(filters[key].toLowerCase());
    });

    return searchMatch && filterMatch;
  });

  const handleFilterChange = (field, value) => {
    // Update filters state
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [field]: value,
      };

      // Apply all active filters to get new filtered data
      const newFilteredData = data.filter((row) => {
        // Apply modal filters
        const modalFilterMatch = Object.keys(newFilters).every((key) => {
          if (!newFilters[key]) return true; // Skip empty filters
          const rowValue = String(row[key] || "").toLowerCase();
          const filterValue = String(newFilters[key]).toLowerCase();
          return rowValue.includes(filterValue);
        });

        // Apply search query filter
        const searchMatch = searchQuery
          .split(" ")
          .every((word) =>
            Object.values(row).some((value) =>
              String(value).toLowerCase().includes(word.toLowerCase().trim())
            )
          );

        // Apply DataGrid filters
        const gridFilterMatch = gridFilterModel.items.every((filterItem) => {
          if (!filterItem.value) return true;

          const cellValue = row[filterItem.field];
          const filterValue = filterItem.value;

          switch (filterItem.operator) {
            case "contains":
              return String(cellValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase());
            case "equals":
              return String(cellValue) === String(filterValue);
            case "startsWith":
              return String(cellValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase());
            case "endsWith":
              return String(cellValue)
                .toLowerCase()
                .endsWith(String(filterValue).toLowerCase());
            default:
              return true;
          }
        });

        return searchMatch && modalFilterMatch && gridFilterMatch;
      });

      // Update filteredRowsData with new filtered results
      setFilteredRowsData(newFilteredData);

      return newFilters;
    });
  };

  const handleDeselectCheckboxes = () => {
    setCheckedRows([]);
  };

  const columnHeaders = {
    status: "Status",
    username: "Username",
    fullname_en: "Full Name",
    suffix: "Suffix",
    plan_name: "Plan Name",
    labels: "Labels",
    ip_address: "IP Address",
    site: "Site/Core",
    subscription_secret: "Token",
    dealer_en: "Reseller",
    connectivity: "Connectivity",
    consumption_option: "Option",
    points: "Omegas",
    due: "Due",
    payment_date: "Payment",
    mac_address: "Mac Address",
    expiration_date: "Expiry",
    creation_date: "Creation",
  };

  // Function to transform the rows based on columnHeaders
  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  // Function to export to Excel
  const handleExportToExcel = () => {
    // Transform the rows to match the new column headers
    const transformedRows =
      filteredRowsData.length > 0
        ? transformRowsForExport(filteredRowsData)
        : transformRowsForExport(filteredRows);

    const worksheet = XLSX.utils.json_to_sheet(transformedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ResellerSubscription");
    XLSX.writeFile(workbook, "ResellerSubscription.xlsx");
  };
  const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: "14px",
          border: "none",
          backgroundColor: isPressed ? "white" : "transparent", // Change background on press
          color: isPressed ? "black" : "#007bff", // Change text color on press
          cursor: "pointer",
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: "20px",
            marginRight: "8px",
            filter: isPressed ? "invert(0)" : "invert(1)", // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
      <Button
        onClick={() => setShowFilterModal(true)}
        variant="outline-primary"
      >
        Filter
      </Button>
    </GridToolbarContainer>
  );
  const CustomFooter = ({ selectedCount }) => {
    return (
      <GridFooterContainer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ marginLeft: "16px", color: "white" }}>
          {`Selected rows: ${selectedCount}`}
        </Typography>
        <GridPagination />
      </GridFooterContainer>
    );
  };
  return (
    <div className="InternetTable ">
      <div className="actions">
        <CustomizedActions
          subscriptions={checkedRows}
          deselect={handleDeselectCheckboxes}
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
              Footer: () => <CustomFooter selectedCount={checkedRows.length} />,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            rowsPerPage={10} // Set the default number of rows per page to 10
            pagination
            disableSelectionOnClick
            filterModel={gridFilterModel}
            onFilterModelChange={(newModel) =>
              setGridFilterModel(newModel) || handleFilterChange
            }
          />
        </div>
      )}
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columns
            .filter((col) => col.field !== "actions")
            .map((col) => (
              <div key={col.field} className="filter">
                <label>{col.headerName}</label>
                <input
                  type="text"
                  placeholder={`Filter by ${col.headerName}`}
                  value={filters[col.field] || ""}
                  onChange={(e) =>
                    handleFilterChange(col.field, e.target.value)
                  }
                  className="filter-input"
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showo} onHide={handleedit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <Formsa
            su={subscriptionDetails.subId}
            intsu={subscriptionDetails.intSubId}
            intpa={subscriptionDetails.intPlanId}
            usr={subscriptionDetails.username}
            full={subscriptionDetails.fullName}
            sit={subscriptionDetails.site}
            con={subscriptionDetails.connectivity}
            opti={subscriptionDetails.consumption}
            plana={subscriptionDetails.planName}
            exp={subscriptionDetails.expDate}
            pass={subscriptionDetails.password}
            due={subscriptionDetails.due}
            payment_date={subscriptionDetails.payment_date}
            label={subscriptionDetails.label}
            site={subscriptionDetails.site_name}
            WAP={subscriptionDetails.WAP}
            core={subscriptionDetails.core}
            mac={subscriptionDetails.mac_address}
            closeModal={handleedit}
          />
        </div>
      </Modal>
    </div>
  );
}

export default SubInternet;
