import React, { useState, useEffect, useContext } from "react";

import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../context/AuthContext";
//For API Requests
import axios from "axios";

function ActionTable({subscription_username}) {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    dealer_id: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/actions/individual/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
            subscription_username:subscription_username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "user",
      headerName: "Initiator",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "action_type",
      headerName: "Type",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "action_description",
      headerName: "Details",
      flex: 2,
      minWidth: 600,
    },
    {
      field: "action_reason",
      headerName: "Reason",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "creation_date",
      headerName: "Date",

      minWidth: 230,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
  ];

  const getRowId = (row) => row.action_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  //Datatable HTML
  return (
    <div className="ClientTable">
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
            sortModel={[
              {
                field: "creation_date",
                sort: "desc", // or 'asc' for ascending order
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default ActionTable;
