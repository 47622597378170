import React, { useState, useEffect, useContext } from "react"; // Import React and hooks
import { API_URL } from "../components/api"; // Import the API URL for backend requests
import Modal from "react-bootstrap/Modal"; // Import Modal component from React-Bootstrap
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid"; // Import Material-UI DataGrid components
import ExcelSVG from "../../ExcelSVG.svg"; // Import Excel icon for export button
import Button from "react-bootstrap/Button"; // Import Button component from React-Bootstrap
import * as XLSX from "xlsx"; // Import XLSX for Excel export functionality
import AuthContext from "../context/AuthContext"; // Import AuthContext for user authentication
import EditLabels from "./EditLabels"; // Import EditApartment component for editing apartments
import axios from "axios"; // Import axios for making API requests

function LabelsTable() {
  // State to store the list of apartments, search query, and loading state
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showFullDetails, setShowFullDetails] = useState(false); // State for displaying full details modal

  // Functions to control modals
  const handleshowDetails = () => setShow(true);
  const handlecloseDetails = () => setShow(false);
  const handleshowFullDetails = () => setShowFullDetails(true);
  const handlecloseFullDetails = () => setShowFullDetails(false);

  const { user } = useContext(AuthContext); // Get user info from context
  const authTokens = JSON.parse(localStorage.getItem("authTokens")); // Get authentication tokens from local storage

  // State for storing full details of an apartment to be edited
  const [fullDetails, setFullDetails] = useState({
    label_id: 0,
    label_name: "",
    label_description: "",
    
  });

  // Function to update full details when editing an apartment
  const updateFullDetails = (fullDetails) => {
    setFullDetails(fullDetails);
  };

  const[filters,setFilters] = useState({
    label_name: "",
    label_description: "",
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  // State for filtered rows data
  const [filteredRowsData, setFilteredRowsData] = useState([]);
  const [gridFilterModel, setGridFilterModel] = useState({
    items: []
  });

  // Function to fetch apartment data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/administration/labels/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`, // Authorization header with token
        },
        params: {
          username: user.username, // Include username in request params
        },
      });

      if (response.status === 200) {
        setData(response.data.reverse()); // Set fetched data in state
        console.log(response.data.reverse()); // Log the data for debugging
      }
    } catch (error) {
      console.error(error); // Log any error
    } finally {
      setLoading(false); // Set loading state to false once data is fetched
    }
  };

  // useEffect hook to fetch data on component mount or when user/username changes
  useEffect(() => {
    fetchData();
  }, [user.username, authTokens.access]);

  // Define columns for DataGrid component
  const columns = [
    { field: "label_name", headerName: "Name", flex: 2, minWidth: 150 },
    
    {
      field: "label_description",
      headerName: "Description",
      minWidth: 230,
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 230,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const fullDetails = {
                label_id: params.row.label_id,
                label_name: params.row.label_name,
                label_description: params.row.label_description,
              };
              updateFullDetails(fullDetails); // Update details to be edited
              handleshowFullDetails(); // Show full details modal
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  // Function to get the row ID for DataGrid
  const getRowId = (row) => row.label_id;

  // useEffect hook to manage session storage for search functionality
  useEffect(() => {
    const searchValue = sessionStorage.getItem("searchLabel");
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchLabel") {
        sessionStorage.removeItem(key); // Clear all session storage except searchApartment
      }
    });
    if (searchValue) {
      sessionStorage.setItem("searchLabel", searchValue);
      setSearchQuery(searchValue); // Restore search query if available
    }
  }, []);

  // Function to handle search input and update session storage
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query); // Update search query in state
    sessionStorage.setItem("searchLabel", query); // Store search query in session storage
  };

  const handleFilterChange = (field, value) => {
    // Update filters state
    setFilters(prevFilters => {
      const newFilters = {
        ...prevFilters,
        [field]: value
      };
  
      // Apply all active filters to get new filtered data
      const newFilteredData = data.filter(row => {
        // Apply modal filters
        const modalFilterMatch = Object.keys(newFilters).every(key => {
          if (!newFilters[key]) return true; // Skip empty filters
          const rowValue = String(row[key] || '').toLowerCase();
          const filterValue = String(newFilters[key]).toLowerCase();
          return rowValue.includes(filterValue);
        });
  
        // Apply search query filter
        const searchMatch = searchQuery.split(" ").every(word =>
          Object.values(row).some(value =>
            String(value).toLowerCase().includes(word.toLowerCase().trim())
          )
        );
  
        // Apply DataGrid filters
        const gridFilterMatch = gridFilterModel.items.every(filterItem => {
          if (!filterItem.value) return true;
          
          const cellValue = row[filterItem.field];
          const filterValue = filterItem.value;
  
          switch (filterItem.operator) {
            case 'contains':
              return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
            case 'equals':
              return String(cellValue) === String(filterValue);
            case 'startsWith':
              return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
            case 'endsWith':
              return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
            default:
              return true;
          }
        });
  
        return searchMatch && modalFilterMatch && gridFilterMatch;
      });
  
      // Update filteredRowsData with new filtered results
      setFilteredRowsData(newFilteredData);
      
      return newFilters;
    });
  };

  // Combined filtering logic
  const filteredRows = data.filter(row => {
    // Apply search query filter
    const searchMatch = searchQuery.split(" ").every(word =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );

    // Apply modal filters
    const modalFilterMatch = Object.keys(filters).every(key => {
      if (!filters[key]) return true; // Skip empty filters
      const rowValue = String(row[key] || '').toLowerCase();
      const filterValue = String(filters[key]).toLowerCase();
      return rowValue.includes(filterValue);
    });

    // Apply DataGrid filters
    const gridFilterMatch = gridFilterModel.items.every(filterItem => {
      if (!filterItem.value) return true;
      
      const cellValue = row[filterItem.field];
      const filterValue = filterItem.value;

      switch (filterItem.operator) {
        case 'contains':
          return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
        case 'equals':
          return String(cellValue) === String(filterValue);
        case 'startsWith':
          return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
        case 'endsWith':
          return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
        default:
          return true;
      }
    });

    // Return true only if all filters match
    return searchMatch && modalFilterMatch && gridFilterMatch;
  });

  // Column headers mapping for export
  const columnHeaders = {
    label_name: "Label Name",
    label_description: "Label Description",
    
  };

  // Transform rows for export (Excel) format
  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  // Export data to Excel
  const handleExportToExcel = () => {
    const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);
    const worksheet = XLSX.utils.json_to_sheet(transformedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Labels");
    XLSX.writeFile(workbook, "Labels.xlsx"); // Download Excel file
  };

  // State and function for handling export button click
  const [isPressed, setIsPressed] = useState(false);
  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel(); // Trigger export
    setTimeout(() => {
      setIsPressed(false); // Reset button state after 200ms
    }, 200);
  };

  // Custom toolbar for DataGrid with export button
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick} // Handle export button click
        variant="outline-success"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: "14px",
          border: "none",
          backgroundColor: isPressed ? "white" : "transparent",
          color: isPressed ? "black" : "#007bff",
          cursor: "pointer",
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: "20px",
            marginRight: "8px",
            filter: isPressed ? "invert(0)" : "invert(1)", // Invert color when pressed
          }}
        />
        Excel
      </Button>
      <Button
        onClick={() => setShowFilterModal(true)}
        variant="outline-primary"
      >Filter</Button>
    </GridToolbarContainer>
  );

  // Custom footer for DataGrid with pagination
  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch} // Update search query on input change
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows} // Pass filtered rows to DataGrid
            columns={columns} // Pass column definitions
            getRowId={getRowId} // Function to get row ID
            filterModel={gridFilterModel}
            onFilterModelChange={(newModel) => setGridFilterModel(newModel) || handleFilterChange}
            components={{
              Toolbar: CustomToolbar, // Custom toolbar component
              Footer: CustomFooter, // Custom footer component
            }}
            rowsPerPageOptions={[10, 100, 1000]} // Pagination options
            pagination
            disableSelectionOnClick // Disable row selection on click
          />
        </div>
      )}

      <Modal show={showFullDetails} onHide={handlecloseFullDetails} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Building</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <EditLabels
            label_id={fullDetails.label_id}
            label_name={fullDetails.label_name}
            label_description={fullDetails.label_description}
          />
        </div>
      </Modal>
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columns
            .filter((col) => col.field !== "actions")
            .map((col) => (
              <div key={col.field} className="filter">
                <label>{col.headerName}</label>
                <input
                  type="text"
                  placeholder={`Filter by ${col.headerName}`}
                  value={filters[col.field] || ""}
                  onChange={(e) => handleFilterChange(col.field, e.target.value)}
                  className="filter-input"
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LabelsTable;
