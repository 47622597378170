import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../components/api";
import OnboardingEdit from "./OnboardingEdit";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../context/AuthContext";

// For API Requests
import axios from "axios";

function OnboardingTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [ClientDetails, setClientDetails] = useState({
    client_id: 0,
    onboarding_id: 0,
    previous: "",
    app: "",
    whatsapp: "",
    notes: "",
    phone: "",
    expiry: "",
    referral: "",
    mgrs: "",
    recipient: "",
  });

  const updateClientDetails = (details) => {
    setClientDetails(details);
  };

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/radius/subscriptions/onboarding/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username]);

  const columns = [
    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 250 },
    {
      field: "phone_number",
      headerName: "Phone",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "expiration_day",
      headerName: "Expiry",
      flex: 2,
      minWidth: 100,
    },

    {
      field: "app_installed",
      headerName: "App Installed",
      flex: 2,
      minWidth: 100,
      valueFormatter: (params) => (params.value === 1 ? "Yes" : "No"),
    },
    {
      field: "whatsapp_proof",
      headerName: "WhatsApp Proof",
      flex: 2,
      minWidth: 130,
      valueFormatter: (params) => (params.value === 1 ? "Yes" : "No"),
    },
    {
      field: "payment_method",
      headerName: "Method",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "previous_reseller",
      headerName: "Previous",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "referral",
      headerName: "Referral",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "recipient",
      headerName: "Recipient",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "mgrs",
      headerName: "Location",
      flex: 2,
      minWidth: 250,
    },
    { field: "employee_en", headerName: "Cashier", flex: 2, minWidth: 200 },
    { field: "notes", headerName: "Notes", flex: 2, minWidth: 250 },

    {
      field: "date_created",
      headerName: "Date",
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "creation_date",
      headerName: "Subscription Creation Date",
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 70,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                client_id: params.row.client_id,
                onboarding_id: params.row.client_onboarding_id,
                whatsapp: params.row.whatsapp_proof,
                previous: params.row.previous_reseller,
                notes: params.row.notes,
                payment: params.row.payment_method,
                app: params.row.app_installed,
                phone_number: params.row.phone_number,
                referral: params.row.referral,
                recipient: params.row.recipient,
                mgrs: params.row.mgrs,
                expiry: params.row.expiration_date,
              };
              updateClientDetails(details);
              handleShow();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.client_onboarding_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const sumField = (field) => {
    return filteredRows.reduce((total, row) => {
      const value = parseFloat(row[field]);
      return isNaN(value) ? total : total + value;
    }, 0);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Data</Modal.Title>
        </Modal.Header>
        <div className="forma">
          <OnboardingEdit
            client_id={ClientDetails.client_id}
            onboarding_id={ClientDetails.onboarding_id}
            notes={ClientDetails.notes}
            app={ClientDetails.app}
            payment={ClientDetails.payment}
            whatsapp={ClientDetails.whatsapp}
            previous={ClientDetails.previous}
            phone={ClientDetails.phone_number}
            expiry={ClientDetails.expiry}
            mgrs={ClientDetails.mgrs}
            recipient={ClientDetails.recipient}
            referral={ClientDetails.referral}
            close={handleClose}
            fetch={fetchData}
          />
        </div>
      </Modal>
    </div>
  );
}

export default OnboardingTable;
