import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert"; // Import Alert for pop-up messages
import Select from "react-select";

function EditWAP(props) {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [name, setName] = useState("");
  const [mgrs, setMgrs] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Destructure props
  const { site_id: id, name: n, mgrs:m } = props;

 


  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post(
      `${API_URL}/radius/sites/`,
      {
        Action: "Put",
        site_id: id,
        name: name || n,
        mgrs: mgrs || m,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log("Site updated:", response);
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Auto-hide after 3 seconds
      })
      .catch((error) => {
        console.error("Error updating Site:", error);
        setErrorMessage(
          error.response?.data?.message || "An error occurred while updating the Site."
        );
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Auto-hide after 5 seconds
      });
  };

  return (
    <>
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          Site updated successfully!
        </Alert>
      )}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      <Modal.Body>
      <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Site Name:</label>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)} // Update apartment name on change
          placeholder={n}
        />
      </div>
      <div className="form-group">
        <label>Mgrs:</label>
        <input
          type="text"
          
          className="form-control"
          value={mgrs}
          onChange={(e) => setMgrs(e.target.value)} // Update level on change
          placeholder={m}
        />
      </div>
      {/* Submit button to add Site */}
      <button type="submit" className="btn btn-success" style={{ marginTop: "10px" }}>
        Save Changes
      </button>
    </form>
      </Modal.Body>
    </>
  );
}

export default EditWAP;
