import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import Modal from "react-bootstrap/Modal";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import DrawerData from "./drawerData";
import AuthContext from "../../context/AuthContext";

// For API Requests
import axios from "axios";

function DrawerTable() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [showdetails, setShowDetails] = useState(false);
  const handleshowDetails = () => setShowDetails(true);
  const handlecloseDetails = () => setShowDetails(false);
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [dealerDetails, setDealerDetails] = useState({
    daily_drawer_id: 0,
  });
  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/inventory/drawer/daily/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data.reverse());
          console.log(response.data.reverse());
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username, authTokens.access]);

  const columns = [
    { field: "name", headerName: "Name", flex: 2, minWidth: 150 },
    {
      field: "balance_usd",
      headerName: "Balance USD",
      minWidth: 230,
    },
    {
      field: "balance_lbp",
      headerName: "Balance LBP",
      minWidth: 230,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 2,
      minWidth: 230,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        date.setHours(date.getHours());
        const formattedDate = date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        const [month, day, year] = formattedDate.split("/");
        const flippedDate = `${day}/${month}/${year}`;
        const time = date.toLocaleTimeString();
        return `${flippedDate} ${time}`;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 230,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                daily_drawer_id: params.row.daily_drawer_id,
              };
              updateDealerDetails(details);
              handleshowDetails();
            }}
          >
            Details
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.daily_drawer_id;

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const sumClosingBalance = filteredRows.reduce((total, row) => {
    const closingBalance = parseInt(row.balance_usd);
    return isNaN(closingBalance) ? total : total + closingBalance;
  }, 0);

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        <div>Total Closing Balance: {sumClosingBalance}</div>
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooter,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal
        show={showdetails}
        onHide={handlecloseDetails}
        className="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <DrawerData daily_drawer_id={dealerDetails.daily_drawer_id} />
      </Modal>
    </div>
  );
}

export default DrawerTable;
