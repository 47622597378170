import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import Axios from "axios";

import "./sub.css";
import Formi from "./createsub";
import SubInternet from "./subinternettable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Internetsub() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState(0);
  const [expiryDateDisabled, setExpiryDateDisabled] = useState(true);
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  
  useEffect(() => {
    let isMounted = true;
    const getpermisions = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/groups/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setExpiryDateDisabled(response.data === "yes");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getpermisions();

    return () => {
      isMounted = false;
    };
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="internetsub">
      <div className="main-content">
        <div className="add-subscription">
          {/*<button onClick={handleShow} className="add-button">
            Add Subscription
          </button>*/}

          <Modal show={show} onHide={handleClose} className="modal-lg">
            <div className="modal-content">
              <Modal.Header closeButton>
                <Modal.Title>Add Subscription</Modal.Title>
              </Modal.Header>
              <Formi closeModal={handleClose} />
            </div>
          </Modal>
        </div>
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: "white",
              },
            }}
          >
            <Tab label="Total" {...a11yProps(0)} sx={{ color: "white" }} />
            <Tab label="Active" {...a11yProps(1)} sx={{ color: "white" }} />

            <Tab label="Online" {...a11yProps(2)} sx={{ color: "white" }} />
            <Tab label="Offline" {...a11yProps(3)} sx={{ color: "white" }} />
            <Tab label="Expired" {...a11yProps(4)} sx={{ color: "white" }} />
            <Tab label="Hold" {...a11yProps(5)} sx={{ color: "white" }} />
            <Tab label="Inactive" {...a11yProps(6)} sx={{ color: "white" }} />
            <Tab label="Free" {...a11yProps(7)} sx={{ color: "white" }} />
            {expiryDateDisabled && (
              <Tab label="Deleted" {...a11yProps(8)} sx={{ color: "white" }} />
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SubInternet url={""} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SubInternet url={"active"} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SubInternet url={"online"} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SubInternet url={"offline"} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SubInternet url={"expired"} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <SubInternet url={"hold"} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <SubInternet url={"inactive"} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <SubInternet url={"free"} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <SubInternet url={"deleted"} />
        </TabPanel>
      </div>
    </div>
  );
}

export default Internetsub;
