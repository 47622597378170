import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./order.css";
function OrderForm() {
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
  const [isAddServiceModalOpen, setAddServiceModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedService, setSelectedService] = useState([]);

  const [productQuantity, setProductQuantity] = useState(1);
  const [serviceQuantity, setServiceQuantity] = useState(1);

  const [addedProducts, setAddedProducts] = useState([]);
  const [Clients, setClients] = useState([]);
  const [client, setClient] = useState("");

  const [Products, setProducts] = useState([]);
  const [Services, setServices] = useState([]);

  const [addedServices, setAddedServices] = useState([]);

  const [selectedDate, setSelectedDate] = useState(getDefaultDate());
  const [newSubscription, setNewSubscription] = useState(false);
  const [message, setMessage] = useState(null);
  const [confirmNewSubscriptionOpen, setConfirmNewSubscriptionOpen] =
    useState(false);
  const [confirmNewSubscription, setConfirmNewSubscription] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isOffersModalOpen, setOffersModalOpen] = useState(false); // New state for offers modal
  const [username, setUsername] = useState(null);
  const [usernames, setUsernames] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [sites, setSites] = useState([]);
  const [cores, setCores] = useState([]);
  const [wirelessAccessPoints, setWirelessAccessPoints] = useState([]);

  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedCore, setSelectedCore] = useState(null);
  const [selectedWirelessAccessPoint, setSelectedWirelessAccessPoint] =
    useState(null);

  const history = useHistory();
  console.log(selectedCore);
  const validate = () => {
    return (
      client !== "" &&
      (addedServices.length !== 0 || addedProducts.length !== 0)
    );
  };

  useEffect(() => {
    let isMounted = true;

    const getProducts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/products/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setProducts(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getClients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setClients(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getServices = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/services/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setServices(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getProducts();
    getClients();
    getServices();

    return () => {
      isMounted = false;
    };
  }, []);

  const clientOptions = Clients
    ? Clients.map((item) => ({
        label: item.fullname_en,
        value: item.client_id,
      }))
    : [];

  const productOptions = Products.map((item) => ({
    label: item.name,
    value: item.product_id,
    selling_price: item.selling_price,
  }));
  const serviceOptions = Services.map((item) => ({
    label: item.name,
    value: item.service_id,
    selling_price: item.selling_price,
  }));

  function getDefaultDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleAddProductClick = () => {
    setAddProductModalOpen(true);
  };

  const handleAddServiceClick = () => {
    setAddServiceModalOpen(true);
  };

  const handleAddProductModalClose = () => {
    setAddProductModalOpen(false);
    setProductQuantity("1");
  };

  const handleAddServiceModalClose = () => {
    setAddServiceModalOpen(false);
    setServiceQuantity("1");
  };

  const customFilter = (option, inputValue) => {
    const words = inputValue.toLowerCase().split(" ");
    return words.every((word) => option.label.toLowerCase().includes(word));
  };
  const handleClientChange = (selectedOption) => {
    setClient(selectedOption);
    const filteredUsernames = Clients.filter(
      (item) => item.client_id === selectedOption.value
    ).map((item) => ({
      label: item.username,
      value: item.subscription_id,
    }));
    setUsernames(filteredUsernames);
    setUsername(null); // Reset the username selection when client changes
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...addedProducts];
    updatedProducts.splice(index, 1);
    setAddedProducts(updatedProducts);
  };

  const handleDeleteService = (index) => {
    const updatedServices = [...addedServices];
    updatedServices.splice(index, 1);
    setAddedServices(updatedServices);
  };

  const handleAddProduct = () => {
    const newProduct = {
      id: selectedProduct.value,
      name: selectedProduct.label,
      quantity: productQuantity,
      selling_price: isFree
        ? 0
        : selectedProduct.selling_price * productQuantity, // Set sellingPrice to 0 if isFree is true
      is_free: isFree, // Include isFree value
    };
    setAddedProducts([...addedProducts, newProduct]);
    console.log(selectedProduct);
    handleAddProductModalClose();
    setIsFree(false); // Reset the checkbox state
  };

  const handleAddService = () => {
    const newService = {
      id: selectedService.value,
      name: selectedService.label,
      quantity: serviceQuantity,
      selling_price: isFree
        ? 0
        : selectedService.selling_price * serviceQuantity, // Set sellingPrice to 0 if isFree is true
      is_free: isFree, // Include isFree value
    };
    const specialServiceNames = [
      "Basic",
      "Light",
      "Popular",
      "Premium",
      "Gold",
      "Platinum",
      "Diamond",
    ];

    const isSpecialService = specialServiceNames.some((serviceName) =>
      newService.name.toLowerCase().includes(serviceName.toLowerCase())
    );

    if (isSpecialService) {
      setConfirmNewSubscriptionOpen(true);

      // Remove all special services from the list, including the selected one
      const filteredServices = Services.filter(
        (service) =>
          !specialServiceNames.some((serviceName) =>
            service.name.toLowerCase().includes(serviceName.toLowerCase())
          ) || service.id === newService.id
      );

      setServices(filteredServices);
    } else {
      setAddedServices([...addedServices, newService]);
      handleAddServiceModalClose();
      setIsFree(false); // Reset the checkbox state
    }
  };

  const handleConfirmNewSubscription = () => {
    setNewSubscription(true);
    setAddedServices([
      ...addedServices,
      {
        id: selectedService.value,
        name: selectedService.label,
        quantity: serviceQuantity,
        selling_price: isFree
          ? 0
          : selectedService.selling_price * serviceQuantity, // Set sellingPrice to 0 if isFree is true
        is_free: isFree, // Include isFree value
      },
    ]);

    handleAddServiceModalClose();
    setConfirmNewSubscriptionOpen(false);
  };
  const handleCancelNewSubscription = () => {
    setNewSubscription(false);
    setAddedServices([
      ...addedServices,
      {
        id: selectedService.value,
        name: selectedService.label,
        quantity: serviceQuantity,
        selling_price: isFree
          ? 0
          : selectedService.selling_price * serviceQuantity, // Set sellingPrice to 0 if isFree is true
        is_free: isFree,
      },
    ]);
    handleAddServiceModalClose();
    setConfirmNewSubscriptionOpen(false);
  };

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
      //history.push("/accounting/order");
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.post(
        `${API_URL}/inventory/order/new/`,
        {
          Action: "Post",
          products: addedProducts,
          services: addedServices,
          client: client,
          date: selectedDate,
          productquantity: productQuantity,
          servicequantity: serviceQuantity,
          userdealer: user.username,
          newSubscription: newSubscription,
          site: selectedSite?.value || null,
          wireless_access_point: selectedWirelessAccessPoint?.value || null,
          core: selectedCore?.value || null,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      const { username, password } = response.data;

      // Set credentials and open the dialog
      setCredentials({ username, password });
      setConfirmNewSubscription(true);
      setDialogOpen(true); // Open the dialog

      showMessage("Order successful!", "success");
    } catch (error) {
      console.log(error);
      showMessage("Order failed!", "danger");
    }
  };

  const offers = {
    offer1: {
      products: [
        { name: "SXT sq New", quantity: 1 },
        { name: "Cudy 1200", quantity: 1 },
        { name: "Indoor Cat6 Cable", quantity: 35 }, // assuming 35 meters is the quantity
      ],
    },
    offer2: {
      products: [
        { name: "LDF New", quantity: 1 },
        { name: "Cudy 1200", quantity: 1 },
        { name: "Indoor Cat6 Cable", quantity: 35 },
      ],
    },
    offer3: {
      products: [
        { name: "LHG New", quantity: 1 },
        { name: "Cudy 1200", quantity: 1 },
        { name: "Indoor Cat6 Cable", quantity: 35 },
      ],
    },
    offer4: {
      products: [
        { name: "SXT sq Used", quantity: 1 },
        { name: "Cudy 1200", quantity: 1 },
        { name: "Indoor Cat6 Cable", quantity: 35 },
      ],
    },
    offer5: {
      products: [
        { name: "LDF Used", quantity: 1 },
        { name: "Cudy 1200", quantity: 1 },
        { name: "Indoor Cat6 Cable", quantity: 35 },
      ],
    },
  };

  // Function to open the Offers Modal
  const handleOpenOffersModal = () => {
    setOffersModalOpen(true);
  };

  // Function to close the Offers Modal
  const handleCloseOffersModal = () => {
    setOffersModalOpen(false);
  };
  const handleOfferClick = (offerKey) => {
    const offer = offers[offerKey]; // Get the offer based on the offerKey
    console.log("Selected offer:", offer); // Log the selected offer

    // Fetch products from the getProduct function (assuming it returns a list of products)
    const allProducts = Products; // Adjust this call based on how you fetch products
    console.log("All products:", allProducts); // Log all products

    const offerProducts = offer.products.map((product) => {
      // Find the matching product by name
      const matchedProduct = allProducts.find((p) => p.name === product.name);
      console.log("Matched product:", matchedProduct); // Log the matched produc

      return {
        id: matchedProduct.product_id, // Use matched product ID if found, else use offer product ID
        name: product.name,
        quantity: product.quantity,
        selling_price: isFree
          ? 0
          : matchedProduct.selling_price * product.quantity, // Set to 0 if isFree is true
        is_free: isFree, // Determine if the product is free
      };
    });

    console.log("Offer products to be added:", offerProducts); // Log the products being added

    // Add the offer products to the addedProducts state
    setAddedProducts((prevAddedProducts) => [
      ...prevAddedProducts,
      ...offerProducts,
    ]);
    setIsFree(false); // Reset the checkbox state after handling the offer click
  };

  // Function to render offers in a table format
  const renderOffersTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Offer</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(offers).map(([offerKey, offer], index) =>
            offer.products.map((product, productIndex) => (
              <TableRow
                key={`${index}-${productIndex}`}
                onClick={() => handleOfferClick(offerKey)} // Make the entire row clickable
                style={{ cursor: "pointer" }} // Change cursor to pointer on hover
              >
                {productIndex === 0 && (
                  <TableCell rowSpan={offer.products.length}>
                    {offerKey}
                  </TableCell>
                )}
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.quantity}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/sites/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: { username: user.username },
        });
        if (response.status === 200) {
          const options = response.data.map((item) => ({
            label: item.site_name,
            value: item.site_id,
          }));
          setSites(options);
        }
      } catch (error) {
        console.error("Error fetching sites:", error);
      }
    };
    fetchSites();

    const fetchCores = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/radius/fiber/cores/select/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: { username: user.username },
          }
        );
        if (response.status === 200) {
          const options = response.data.map((item) => ({
            label: item.code,
            value: item.core_id,
          }));
          setCores(options);
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching cores:", error);
      }
    };
    fetchCores();
  }, [authTokens.access, user.username]);

  const fetchWirelessAccessPoints = async (siteName) => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/wirelessAccessPoint/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: { username: user.username, site: siteName },
        }
      );
      if (response.status === 200) {
        const options = response.data.map((item) => ({
          label: item.name,
          value: item.ap_id,
        }));
        setWirelessAccessPoints(options);
      }
    } catch (error) {
      console.error("Error fetching wireless access points:", error);
    }
  };

  // Function to check if any selected services are Wireless or Fiber
  const hasWirelessService = () => {
    return addedServices.some((service) =>
      service.name.toLowerCase().startsWith("wireless")
    );
  };

  const hasFiberService = () => {
    return addedServices.some((service) =>
      service.name.toLowerCase().startsWith("fiber")
    );
  };

  // Handle site selection and trigger wireless access point fetch
  const handleSiteChange = (selectedOption) => {
    setSelectedSite(selectedOption);
    if (selectedOption) {
      fetchWirelessAccessPoints(selectedOption.label);
    }
  };

  return (
    <div className="order">
      <div className="order-header">
        <div className="form-group">
          <label>
            Select Client:
            <Select
              className="search-line"
              placeholder="Choose Client"
              options={clientOptions}
              onChange={handleClientChange}
              filterOption={customFilter}
              isSearchable={true}
            />
          </label>
        </div>
        <div>
          <label htmlFor="order-date">Order Date:</label>
          <input
            type="date"
            id="order-date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{ color: "aliceblue" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label>
          New Subscription:
          <input
            type="checkbox"
            checked={newSubscription}
            onChange={(e) => setNewSubscription(e.target.checked)}
          />
        </label>
      </div>

      <div className="order-body">
        <div className="table-container-products">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Selling Price</TableCell>
                  <TableCell>Is Free</TableCell> {/* New Column */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedProducts.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.selling_price}</TableCell>
                    <TableCell>{product.is_free ? "Yes" : "No"}</TableCell>{" "}
                    {/* Display if it's free */}
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteProduct(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddProductClick}
            className="buttonWithMargin"
          >
            Add Product
          </Button>
        </div>

        <div className="table-container-services">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Selling Price</TableCell>
                  <TableCell>Is Free</TableCell> {/* New Column */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedServices.map((service, index) => (
                  <TableRow key={index}>
                    <TableCell>{service.name}</TableCell>
                    <TableCell>{service.quantity}</TableCell>
                    <TableCell>{service.selling_price}</TableCell>
                    <TableCell>{service.is_free ? "Yes" : "No"}</TableCell>{" "}
                    {/* Display if it's free */}
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteService(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddServiceClick}
            className="buttonWithMargin"
          >
            Add Service
          </Button>
        </div>
      </div>

      <Dialog open={isAddProductModalOpen} onClose={handleAddProductModalClose}>
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent className="modal-content">
          <div className="form-group">
            <label>
              Select Product:
              <Select
                className="search-line"
                placeholder="Choose Product"
                options={productOptions}
                onChange={(opt) => setSelectedProduct(opt)}
              />
            </label>
          </div>
          <label>
            Quantity:
            <input
              className="form-control"
              type="text"
              value={productQuantity}
              required
              onChange={(e) => setProductQuantity(e.target.value)}
            />
          </label>
          <label>
            Is Free:
            <input
              type="checkbox"
              checked={isFree}
              onChange={(e) => setIsFree(e.target.checked)}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddProductModalClose}>Cancel</Button>
          <Button
            onClick={handleAddProduct}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmNewSubscriptionOpen}
        onClose={() => setConfirmNewSubscriptionOpen(false)}
      >
        <DialogTitle>New Subscription</DialogTitle>
        <DialogContent>
          <p>Would you like to create a new subscription for this service?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelNewSubscription}>No</Button>
          <Button onClick={handleConfirmNewSubscription} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddServiceModalOpen} onClose={handleAddServiceModalClose}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent className="modal-content">
          <div className="form-group">
            <label>
              Select Service:
              <Select
                className="search-line"
                placeholder="Choose Service"
                options={serviceOptions}
                onChange={(opt) => setSelectedService(opt)}
              />
            </label>
          </div>
          <label>
            Quantity:
            <input
              className="form-control"
              type="text"
              value={serviceQuantity}
              required
              onChange={(e) => setServiceQuantity(e.target.value)}
            />
          </label>
          <label>
            Is Free:
            <input
              type="checkbox"
              checked={isFree}
              onChange={(e) => setIsFree(e.target.checked)}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddServiceModalClose}>Cancel</Button>
          <Button
            onClick={handleAddService}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenOffersModal}
        style={{ marginBottom: "20px" }}
      >
        View Offers
      </Button>

      {/* Offers Modal */}
      <Dialog open={isOffersModalOpen} onClose={handleCloseOffersModal}>
        <DialogTitle>Available Offers</DialogTitle>
        <DialogContent>{renderOffersTable()}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOffersModal}>Close</Button>
        </DialogActions>
      </Dialog>

      {confirmNewSubscription && (
        <Dialog open onClose={() => setConfirmNewSubscription(false)}>
          <DialogTitle>Credentials</DialogTitle>
          <DialogContent>
            <p>Username: {credentials.username}</p>
            <p>Password: {credentials.password}</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmNewSubscription(false)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Button
        variant="contained"
        onClick={handleSubmit}
        className="submit"
        disabled={!validate()}
      >
        Submit
      </Button>
      {hasWirelessService() && (
        <div className="form-group">
          <label>
            Select Site:
            <Select
              className="search-line"
              placeholder="Choose Site"
              options={sites}
              onChange={handleSiteChange}
              value={selectedSite}
            />
          </label>
        </div>
      )}

      {/* Conditional rendering for wireless access point selection */}
      {hasWirelessService() && selectedSite && (
        <div className="form-group">
          <label>
            Select Wireless Access Point:
            <Select
              className="search-line"
              placeholder="Choose Wireless Access Point"
              options={wirelessAccessPoints}
              onChange={(opt) => setSelectedWirelessAccessPoint(opt)}
              value={selectedWirelessAccessPoint}
            />
          </label>
        </div>
      )}

      {/* Conditional rendering for core selection */}
      {hasFiberService() && (
        <div className="form-group">
          <label>
            Select Core:
            <Select
              className="search-line"
              placeholder="Choose Core"
              options={cores}
              onChange={(opt) => setSelectedCore(opt)}
              value={selectedCore}
            />
          </label>
        </div>
      )}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default OrderForm;
