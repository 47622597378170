import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import Modal from "react-bootstrap/Modal";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import ExcelSVG from "../../ExcelSVG.svg";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import AuthContext from "../context/AuthContext";
import EditONU from "./EditONU";
import ONUAdd from "./ONUAdd";

// For API Requests
import axios from "axios";

function ONUTable() {
  // State hooks for managing data and UI states
  const [data, setData] = useState([]); // Stores the data fetched from the API
  const [searchQuery, setSearchQuery] = useState(""); // Tracks the search query entered by the user
  const [loading, setLoading] = useState(true); // Determines if data is still loading
  const [show, setShow] = useState(false); // Manages modal visibility for details
  const [showFullDetails, setShowFullDetails] = useState(false); // Manages modal visibility for full details
  const { user } = useContext(AuthContext); // Accessing the current user context
  const authTokens = JSON.parse(localStorage.getItem("authTokens")); // Fetching auth token from localStorage
  const handleshowFullDetails = () => setShowFullDetails(true);
  const handlecloseFullDetails = () => setShowFullDetails(false);

  const [OnuAdd, setOnuAdd] = useState(false); // Manages modal visibility for full details
  const handleshowAdd = () => setOnuAdd(true);
  const handlecloseAdd = () => setOnuAdd(false);

  // Full details state for storing the currently selected ONU's data
  const [fullDetails, setFullDetails] = useState({
    onu_id: 0,
    onu_name: "",
    mac_address: "",
    pon: "",
  });

  // Updates the full details for a selected ONU
  const updateFullDetails = (fullDetails) => {
    setFullDetails(fullDetails);
  };

  // State to manage filtered data based on search or filters
  const [filteredRowsData, setFilteredRowsData] = useState([]);

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/radius/fiber/cores/onu/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`, // Authorization token in the header
        },
        params: {
          username: user.username, // Include the username in the query params
        },
      });

      // If the response is successful, reverse the data and set it
      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data.reverse()); // Log the data for debugging
      }
    } catch (error) {
      console.error(error); // Log any errors during the API call
    } finally {
      setLoading(false); // Set loading to false after the data is fetched
    }
  };

  // Fetch data when component mounts or user/authorization changes
  useEffect(() => {
    fetchData();
  }, [user.username, authTokens.access]);

  // Defines the columns for the DataGrid table
  const columns = [
    { field: "onu_name", headerName: "ONU Name", flex: 2, minWidth: 150 },
    { field: "mac_address", headerName: "Mac Address", flex: 2, minWidth: 150 },
    {
      field: "pon",
      headerName: "PON",
      minWidth: 230,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 230,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              // Set full details for the selected ONU and show full details modal
              const fullDetails = {
                onu_id: params.row.onu_id,
                onu_name: params.row.onu_name,
                mac_address: params.row.mac_address,
                pon: params.row.pon,
              };
              updateFullDetails(fullDetails);
              handleshowFullDetails();
            }}
          >
            Edit
          </button>
          <button
            className="edit-client"
            onClick={() => {
              // Set full details for the selected ONU and show full details modal
              const fullDetails = {
                onu_id: params.row.onu_id,
                onu_name: params.row.onu_name,
                mac_address: params.row.mac_address,
                pon: params.row.pon,
              };
              updateFullDetails(fullDetails);
              handleshowAdd();
            }}
          >
            Add ONU
          </button>
        </div>
      ),
    },
  ];

  // Define the row ID for the DataGrid
  const getRowId = (row) => row.onu_id;

  // Effect to handle session storage for search query
  useEffect(() => {
    const searchValue = sessionStorage.getItem("searchONU"); // Get saved search value from session storage
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchONU") {
        sessionStorage.removeItem(key); // Clear other session data but keep search query
      }
    });

    if (searchValue) {
      sessionStorage.setItem("searchONU", searchValue);
      setSearchQuery(searchValue); // Set the search query if available
    }
  }, []);

  // Handler for search input change
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchONU", query); // Save search query to session storage
  };

  // Filter data based on search query
  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  // Handler for filtering table rows based on selected filters
  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;

          const cellValue = row[columnField];
          if (cellValue !== undefined && value !== undefined) {
            return String(cellValue)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
          return false; // Exclude rows if value is undefined
        });
      });
      setFilteredRowsData(filteredData); // Set filtered data
    } else {
      setFilteredRowsData(data); // No filters, reset to original data
    }
  };

  // Column headers for export
  const columnHeaders = {
    onu_name: "Name",
    mac_address: "Mac Address",
    pon: "PON",
  };

  // Transform rows data for export to Excel
  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  // Export data to Excel file
  const handleExportToExcel = () => {
    const transformedRows =
      filteredRowsData.length > 0
        ? transformRowsForExport(filteredRowsData)
        : transformRowsForExport(filteredRows);

    const worksheet = XLSX.utils.json_to_sheet(transformedRows); // Convert data to sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ONU"); // Add sheet to workbook
    XLSX.writeFile(workbook, "ONU.xlsx"); // Trigger download of the Excel file
  };

  const [isPressed, setIsPressed] = useState(false);

  // Handle export button click
  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel(); // Call the export function
    setTimeout(() => {
      setIsPressed(false); // Reset button state after 200ms
    }, 200);
  };

  // Custom toolbar for the DataGrid (with export button)
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick} // Trigger export when clicked
        variant="outline-success"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: "14px",
          border: "none",
          backgroundColor: isPressed ? "white" : "transparent",
          color: isPressed ? "black" : "#007bff",
          cursor: "pointer",
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: "20px",
            marginRight: "8px",
            filter: isPressed ? "invert(0)" : "invert(1)", // Change icon color on button press
          }}
        />
        Excel
      </Button>
    </GridToolbarContainer>
  );

  // Custom footer for the DataGrid (pagination controls)
  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      {/* Search input for filtering rows */}
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          {/* DataGrid component for displaying rows */}
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooter,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      {/* Modal for displaying full details */}
      <Modal
        show={showFullDetails}
        onHide={handlecloseFullDetails}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Building</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <EditONU
            onu_id={fullDetails.onu_id}
            onu_name={fullDetails.onu_name}
            mac_address={fullDetails.mac_address}
            pon={fullDetails.pon}
          />
        </div>
      </Modal>
      <Modal show={OnuAdd} onHide={handlecloseAdd} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Add ONU</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <ONUAdd
            onu_id={fullDetails.onu_id}
            onu_name={fullDetails.onu_name}
            mac_address={fullDetails.mac_address}
            pon={fullDetails.pon}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ONUTable;
