// Import necessary dependencies and components
import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert"; // Import Alert for messages
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import SiteTable from "./SiteTable";
import Select from "react-select";

// Main Apartments component
function Site() {
  // State hook to manage modal visibility
  const [show, setShow] = useState(false);

  // Functions to handle modal open and close actions
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="Buildings">
      {/* Button to trigger the modal for adding an apartment */}
      <button onClick={handleShow} className="edit-client" style={{marginTop:"10px", marginLeft:"10px"}}>
        Add Site
      </button>
      <div className="main">
        {/* Render ApartmentsTable component to display list of apartments */}
        <SiteTable />
      </div>
      {/* Modal to display the apartment form */}
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* ApartmentForm component is passed parentFunction to close modal */}
          <SiteForm parentFunction={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

// ApartmentForm component where user can add apartment details
function SiteForm(props) {
  // Context hook to get current user details
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  // State hooks to manage form data and UI state
  const [name, setName] = useState("");
  const [mgrs,setMgrs ] = useState("")
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  

  // Function to handle form submission for adding an apartment
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload on form submission

    // API request to add apartment data
    Axios.post(
      `${API_URL}/radius/sites/`,
      {
        Action: "Post",
        name: name,
        mgrs: mgrs,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log("Sites added:", response);
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Hide success message after 3 seconds
        props.parentFunction(); // Close modal after success
      })
      .catch((error) => {
        console.error("Error adding site:", error);
        setErrorMessage(
          error.response?.data?.message || "An error occurred while adding the site."
        );
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Hide error message after 5 seconds
      });
  };
  return (
    <>
      {/* Display success or error messages based on form submission status */}
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          Site added successfully!
        </Alert>
      )}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      {/* Form to input apartment details */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Site Name:</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)} 
            placeholder="Enter site name"
            required
          />
        </div>
        <div className="form-group">
          <label>Mgrs:</label>
          <input
            type="text"
            
            className="form-control"
            value={mgrs}
            onChange={(e) => setMgrs(e.target.value)} 
            placeholder="Enter Location"
            required
          />
        </div>
        {/* Submit button to add site */}
        <button type="submit" className="btn btn-success" style={{ marginTop: "10px" }}>
          Add Site
        </button>
      </form>
    </>
  );
}

export default Site; 
