import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";

function AddProduct() {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [code, setcode] = useState("");
  const [barcode, setbarcode] = useState("");
  const [category, setcategory] = useState("");
  const [cost, setcost] = useState("");
  const [price, setprice] = useState("");
  const [cat, setcat] = useState([]);

  const validate = () => {
    return name !== "";
  };

  useEffect(() => {
    let isMounted = true;
    const getcategories = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/inventory/products/categories/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setcat(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getcategories();

    return () => {
      isMounted = false;
    };
  }, []);

  const options = cat.map((item) => {
    return {
      label: item.category_name,
      value: item.category_id,
    };
  });

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      refreshPage();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/products/`,
      {
        Action: "Post",
        name: name,
        description: description,
        code: code,
        barcode: barcode,
        category: category,
        cost: cost,
        price: price,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Name:
              <input
                className="form-control"
                type="text"
                value={name}
                required
                onChange={(e) => setname(e.target.value)}
              />
            </label>
            <label>
              Description:
              <input
                className="form-control"
                type="text"
                value={description}
                required
                onChange={(e) => setdescription(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Code:
              <input
                className="form-control"
                type="text"
                value={code}
                required
                onChange={(e) => setcode(e.target.value)}
              />
            </label>
            <label>
              Barcode:
              <input
                className="form-control"
                type="text"
                value={barcode}
                required
                onChange={(e) => setbarcode(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Category:
              <Select
                className="search-line"
                placeholder="Choose Client"
                options={options}
                onChange={(opt) => setcategory(opt.value)}
              />
            </label>
          </div>
          <label>
            Cost:
            <input
              className="form-control"
              type="number"
              step="0.1"
              min="0"
              value={cost}
              required
              onChange={(e) => setcost(e.target.value)}
            />
          </label>
          <label>
            Price:
            <input
              className="form-control"
              type="number"
              step="0.1"
              min="0"
              value={price}
              required
              onChange={(e) => setprice(e.target.value)}
            />
          </label>

          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              disabled={!validate()}
            >
              Create
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default AddProduct;
