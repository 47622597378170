import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert"; // Import Alert for pop-up messages
import Select from "react-select";

function EditLabels(props) {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");;

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Destructure props
  const { label_id: i, label_name:n,label_description:d} = props;



  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post(
      `${API_URL}/administration/labels/`,
      {
        Action: "Put",
        label_id: i,
        label_name: name || n,
        label_description: description || d,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log("Label updated:", response);
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Auto-hide after 3 seconds
      })
      .catch((error) => {
        console.error("Error updating label:", error);
        setErrorMessage(
          error.response?.data?.message || "An error occurred while updating the label."
        );
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Auto-hide after 5 seconds
      });
  };

  return (
    <>
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          Label updated successfully!
        </Alert>
      )}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      <Modal.Body>
        <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Label Name:</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)} // Update label name on change
            placeholder={n}
          />
        </div>
        <div className="form-group">
          <label>Label Description:</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)} // Update label description on change
            placeholder={d}
          />
        </div>
          <button type="submit" className="btn btn-success" style={{ marginTop: "10px" }}>
            Save Changes
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default EditLabels;
