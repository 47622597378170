import React from "react";
import TabPanel from "./FiberTabs";

function FiberCores() {
  return (
    <div className="Plans">
      <div className="main">
        <TabPanel />
      </div>
    </div>
  );
}

export default FiberCores;
