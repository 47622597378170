import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import TransferComponent from "./transfer";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport
} from "@mui/x-data-grid";
import ExcelSVG from '../../ExcelSVG.svg';
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Formo from "./Editform";
import Modal from "react-bootstrap/Modal";

import AuthContext from "../context/AuthContext";

//For API Requests
import axios from "axios";

function SubResellerTable() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [transfer, settransfer] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const transferOpen = () => settransfer(true);
  const transferClose = () => settransfer(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  const [DealerDetails, setDealerDetails] = useState({
    dealer_id: 0,
    info_id: 0,
    balance: 0,
    first_name: 0,
    middle_name: 0,
    last_name: "",
    phone_number: "",
    address_string: "",
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/subreseller/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "fullname_en", headerName: "Full Name", flex: 2, minWidth: 250 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "balance_usd",
      headerName: "Balance",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "active_subscriptions",
      headerName: "Active Subscriptions",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        (Number(params.row.dealer_active_subscriptions) || 0) +
        (Number(params.row.parent_active_subscriptions) || 0),
    },
    {
      field: "total_subscriptions",
      headerName: "Total Subscriptions",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        (Number(params.row.dealer_total_subscriptions) || 0) +
        (Number(params.row.parent_total_subscriptions) || 0),
    },
    {
      field: "total_transactions",
      headerName: "Transactions",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "parent_fullname",
      headerName: "Parent",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                dealer_id: params.row.dealer_id,
                info_id: params.row.info_id,
                first_name: params.row.first_name,
                middle_name: params.row.middle_name,
                last_name: params.row.last_name,
                phone_number: params.row.phone_number,
                balance: params.row.balance_id,
              };
              updateDealerDetails(details);
              handleShow();
            }}
          >
            Details
          </button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                dealer_id: params.row.dealer_id,
                info_id: params.row.info_id,
                first_name: params.row.first_name,
                middle_name: params.row.middle_name,
                last_name: params.row.last_name,
                phone_number: params.row.phone_number,
                balance: params.row.balance_id,
              };
              updateDealerDetails(details);
              transferOpen();
            }}
          >
            Transfer
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.dealer_id;
  
  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchReseller");
  
    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchReseller") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchReseller", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);
  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchReseller", query);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;
          
          // Check if row[columnField] is defined
          const cellValue = row[columnField];
          
          if (cellValue !== undefined && value !== undefined) {
            return String(cellValue)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
          
          // If cellValue or value is undefined, return false to exclude this row
          return false;
        });
      });
      setFilteredRowsData(filteredData);
    } else {
      setFilteredRowsData(data); // No filters, reset to original data
    }
  };


  // Column Headers
  const columnHeaders = {
    fullname_en: "Full Name",
    phone_number: "Phone Number",
    balance_usd: "Balance",
    active_subscriptions: "Active Subscriptions",
    total_subscriptions: "Total Subscriptions",
    total_transactions: "Transactions",
  };

  // Transform rows for export
  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  // Handle Excel Export
  const handleExportToExcel = () => {
    const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);
    const worksheet = XLSX.utils.json_to_sheet(transformedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Resellers");
    XLSX.writeFile(workbook, "Resellers.xlsx");
  };

  const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    setTimeout(() => {
      setIsPressed(false);
    }, 200);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          fontSize: '14px',
          border: 'none',
          backgroundColor: isPressed ? 'white' : 'transparent',
          color: isPressed ? 'black' : '#007bff',
          cursor: 'pointer',
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: '20px',
            marginRight: '8px',
            filter: isPressed ? 'invert(0)' : 'invert(1)',
          }}
        />
        Excel
      </Button>
    </GridToolbarContainer>
  );

  //Datatable HTML
  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Dealer</Modal.Title>
        </Modal.Header>
        <div className="forma">
          <Formo
            sub={DealerDetails.dealer_id}
            info={DealerDetails.info_id}
            balance={DealerDetails.balance}
          />
        </div>
      </Modal>
      <Modal show={transfer} onHide={transferClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Transfer Money for {DealerDetails.first_name}{" "}
            {DealerDetails.last_name}{" "}
          </Modal.Title>
        </Modal.Header>
        <div className="forma">
          <TransferComponent
            sub={DealerDetails.dealer_id}
            info={DealerDetails.info_id}
            balance={DealerDetails.balance}
          />
        </div>
      </Modal>
    </div>
  );
}

export default SubResellerTable;
